/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"

import trabajaConNosotros from "../assets/images/trabaja-con-nosotros.png"

import Header from "./header"

import "../assets/css/bootstrap.css"
import "../assets/css/main.css"
import "../assets/css/responsive.css"

import "../assets/css/font-awesome.min.css"
import "../assets/css/_responsive-utilities.css"

import Navigate from "./navigate"
import { Banner1 } from "./imagesSystem"
import { Helmet } from "react-helmet"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  let cliengo = `<script type="text/javascript">(function(){ ldk=document.createElement('script') ldk.type='text/javascript'; ldk.async=true; ldk.src='https://s.cliengo.com/weboptimizer/5ece6c2cc7d8c70028d44f9c/5ece814805b19e0028e5ead0.js'; var s=document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(ldk, s);})();</script>`
  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />

      <main>{children}</main>
      <footer id="footer-wrapper">
        <div id="footer-bottom">
          <div class="container">
            {" "}
            <div className="row">
              <div className="span12">
                <ul>
                  <li>Teléfono Operaciones: 2 2246 0994  </li>
                  <li>Teléfono Administración: 2 2243 3478</li>
                  <li>
                    Celular: <a href="tel:+56933807662">9 3380 7662</a>
                  </li>
                  <li className="email">
                    Email Operaciones:&nbsp;
                    <a
                      href="mailto:operaciones@agenteinmobiliario.cl"
                      target="_blank"
                    >
                      operaciones@agenteinmobiliario.cl
                    </a>
                  </li>
                  <li className="email">
                    Email Ventas:&nbsp;
                    <a
                      href="mailto:contacto@agenteinmobiliario.cl"
                      target="_blank"
                    >
                      propiedades@agenteinmobiliario.cl
                    </a>
                  </li>
                  <li className="email">
                    Email Administración:&nbsp;
                    <a
                      href="mailto:administracion@agenteinmobiliario.cl"
                      target="_blank"
                    >
                      administracion@agenteinmobiliario.cl
                    </a>
                  </li>
                  <li className="address">
                  Casa Matriz: Avenida Providencia 1208, oficina 303, Providencia
                    Santiago de Chile.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <Helmet>
          <script
            type="text/javascript"
            async="true"
            src="https://s.cliengo.com/weboptimizer/5fd3f0f52bb825002a42a1f5/5fd75e48628faa002a254700.js"
          ></script>
        </Helmet>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
