import React, { useEffect, useState, Fragment } from "react"
import axios from "axios"
import sanitizeHtml from "sanitize-html"
import { Link } from "gatsby"
import FormatCurrency from "../../utils/format-currency"
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from "react-icons/fa"

const defaultState = {
  properties: [],
  totalRegisters: 0,
  totalRegistersQuery: 8,
  totalResults: 8,
  hasMore: 0,
  page: 0,
  perPage: 10,
}

const RenderPagination = ({
  currentPage,
  lastPage,
  previusPage,
  location,
  setPage,
}) => {
  console.log("currentPage", currentPage)
  const getPrevPage = () => {
    return location.search.replace(
      `&page=${currentPage}`,
      `&page=${currentPage - 1}`
    )
  }

  const getNextPage = () => {
    let params = new URLSearchParams(location.search)

    console.log('params.get("page")', params.get("page"))

    if (!params.get("page")) {
      if (location.search.length > 0) {
        return `${location.search}&page=1`
      } else {
        return "?&page=1"
      }
    } else {
      return location.search.replace(
        `&page=${currentPage}`,
        `&page=${currentPage + 1}`
      )
    }
  }

  return (
    <div className="pagination" style={{ display: "flow-root", width: "100%" }}>
      {currentPage > 0 && (
        <Link
          to={`${location.pathname}${getPrevPage()}`}
          className="real-btn"
          onClick={() => setPage(currentPage - 1)}
          style={{ display: "inline-flex", alignItems: "center" }}
        >
          <FaArrowAltCircleLeft style={{ marginRight: "5px" }} /> Anterior
        </Link>
      )}

      {/*  <a href="#" className="real-btn current">
        {currentPage} de {lastPage}
      </a> */}
      {parseInt(lastPage) >= currentPage + 1 && (
        <Link
          to={`${location.pathname}${getNextPage()}`}
          className="real-btn"
          onClick={() => setPage(currentPage + 1)}
          style={{ display: "inline-flex", alignItems: "center" }}
        >
          Siguiente <FaArrowAltCircleRight style={{ marginLeft: "5px" }} />
        </Link>
      )}
    </div>
  )
}

const PropertyItem = props => {
  const [page, setPage] = useState(0)
  const [currencyCurrent, setcurrencyCurrent] = useState(null)
  const [loading, setLoading] = useState()
  const [error, setError] = useState(false)
  const [respPropiedades, setRespPropiedades] = useState(defaultState)
  const [searchLocation, setSearchLocation] = useState({
    search: props.location.search,
    pathname: props.location.pathname,
  })
  const [paginationData, setPaginationData] = useState({
    currentPage: 0,
    lastPage: 0,
    totalPage: 0,
  })
 
  const searchProperties = async (
    currentPage,
    propertyType,
    operation,
    comunne,
    sortBy,
    sortOrder,
    currency,
    code, 
    project = null
  ) => {
    setLoading(true)
    setRespPropiedades(defaultState)
  
    if(sortOrder){
      sortBy = '&sortBy=clp'
    }

    if(project){
      project = '&project=true'
    }

    await axios
      .get(
        `https://api.clasihome.com/rest/properties?typeId=office&id=5fbd47668abd7800086f0d10&status=PUBLICADA&limit=20&${propertyType}${operation}${comunne}${sortBy}${sortOrder}${currency}${code}${currentPage}${project}`
      )
      .then(res => {
        if (res.data.properties.length === 0) {
          setError(true)
          setLoading(false)
        } else {
          console.log(res.data)
          setRespPropiedades(res.data)
          setLoading(false)
        }
      })
      .catch()
  }

  const validateParam = (param, successResponse, errorResponse = "") => {
    if(param != null && param != "todas"){
      return successResponse
    }else{
      return errorResponse
    }
  }
  

  useEffect(() => {
    setSearchLocation({
      search: props.location.search,
      pathname: props.location.pathname,
    })
    let search = props.location.search
    let params = new URLSearchParams(search)

    let currentPage = validateParam(params.get("page"),`&page=${params.get("page")}`,`&page=0` ) 
    let propertyType = validateParam(params.get("type") ,`&propertyType=${params.get("type")}` ) 
    let operation = validateParam(params.get("status"),`&operation=${params.get("status")}` ) 
    let comunne = validateParam( params.get("comuna"), `&commune=${params.get("comuna")}` )
    let sortBy = validateParam( params.get("sortBy"), `&sortBy=${params.get("sortBy")}` ) 
    let sortOrder = validateParam( params.get("order"), `&sortOrder=${params.get("order")}` ) 
    let currency = validateParam( params.get("currency"), `&currency=${params.get("currency")}`,  "&currency=CLP" ) 
    let code = validateParam(params.get("code"), `&stringSearch=AGE${params.get("code")}` ) 
    let project = validateParam( params.get("project"), `&project=true` ) 
  

    searchProperties(
      currentPage,
      propertyType,
      operation,
      comunne,
      sortBy,
      sortOrder,
      currency,
      code,
      project
    )
  }, [page])

  /*  const renderPagination = () => {
    const { totalRegistersQuery, perPage, page: pageLink } = respPropiedades
    let lastpage = (totalRegistersQuery / perPage).toFixed(0)
    let currentpage = parseInt(pageLink)

    const prevpage = searchLocation.search.replace(
      `?page=${currentpage}`,
      `?page=${currentpage - 1}`
    )

    if (currentpage === 0) {
      var nextpage = `?page=1&${searchLocation.search}`
    } else {
      var nextpage = searchLocation.search.replace(
        `?page=${currentpage}`,
        `?page=${currentpage + 1}`
      )
    }

    console.log("currentpage", currentpage)
    console.log("lastpage", lastpage)
    console.log("nextpage", currentpage + 1)
    console.log("prevpage", prevpage)

    return (
      <RenderPagination
        currentPage={respPropiedades.page}
        lastPage={lastpage}
        previusPage={prevpage}
        setPage={setPage}
        location={props.location}
      />
    )
  } */

  const sanatizeDescrition = descripcion => {
    if (descripcion.length > 125) {
      return sanitizeHtml(`${descripcion.substring(0, 125)}...`, {
        allowedTags: [],
        allowedAttributes: {
          a: ["href", "target"],
        },
      })
    } else {
      return sanitizeHtml(descripcion)
    }
  }

  return (
    <div>
      {loading && <h4 style={{ padding: "30px 0px 50px 0px" }}>Cargando...</h4>}
      {!loading && error && (
        <Fragment>
          <div className="main">
            {" "}
            <div class="inner-wrapper">
              <article
                class="page type-page clearfix"
                style={{
                  fontSize: "20px",
                  textAlign: "center",
                  padding: "20px",
                }}
              >
                <p>
                  <strong>No se encontró ninguna propiedad</strong>
                </p>
                <Link to="/" class="btn-blue btn-mini">
                  Ir al inicio
                </Link>
              </article>
            </div>
          </div>
        </Fragment>
      )}
      {respPropiedades.properties.length > 0 && (
        <Fragment>
          {respPropiedades.properties.map(p => (
            <div key={p._id} className="span6">
              <article className="property-item clearfix">
                <h4 style={{ height: "65px" }}>
                  <Link to={`detalle-propiedad?id=${p._id}`}>
                    <a>{p.title.substring(0, 125)}</a>
                  </Link>
                </h4>

                <figure style={{ margin: "0px" }}>
                  <span
                    style={{ background: "#dd3333" }}
                    class="property-label"
                  >
                    {p.propertyType == "PANO"
                      ? "PAÑO INMOBILARIO"
                      : p.propertyType == "SITIO"
                      ? "TERRENO"
                      : p.propertyType}
                  </span>
                  <Link to={`detalle-propiedad?id=${p._id}`}>
                    <img
                      src={p.mainImage}
                      alt={p.title}
                      title={p.title}
                      style={{
                        height: "200px",
                        margin: "0px",
                        margin: "0 auto",
                        objectFit: "cover",
                        width: "100%",
                      }}
                    />
                  </Link>
                  <figcaption>En {p.operation}</figcaption>
                </figure>

                <div className="detail" style={{ height: "200px" }}>
                  <h5 className="price">
                    {p.currency === "CLP" ? "$" : p.currency}{" "}
                    {FormatCurrency(p.currency, p.value)}
                  </h5>
                  <p style={{ height: "100px" }}>
                    {sanatizeDescrition(p.publicObservations)}
                  </p>
                  {/*   <Link to={`detalle-propiedad?id=${p.id}`}>
                    <a className="more-details" href="#">
                      Más detalles <i className="icon-caret-right"></i>
                    </a>
                  </Link> */}
                  <div style={{ textAlign: "right" }}>
                    <Link
                      to={`consulta?id=${p.code}&nameAgent=${p._comercialUser_person[0].firstName}%20${p._comercialUser_person[0].lastName}&eAgent=${p._comercialUser[0].email}`}
                      class="btn-grey btn-mini"
                      style={{
                        padding: "5px 15px",
                        marginRight: "10px",
                        backgroundColor: "#464646",
                      }}
                    >
                      Consulta
                    </Link>

                    <Link
                      to={`orden-visita?id=${p.code}&nameAgent=${p._comercialUser_person[0].firstName}%20${p._comercialUser_person[0].lastName}&eAgent=${p._comercialUser[0].email}`}
                      class="btn-grey btn-mini"
                      style={{
                        padding: "5px 15px",
                        backgroundColor: "#464646",
                      }}
                    >
                      Orden de visita
                    </Link>
                  </div>
                </div>

                <div className="property-meta">
                  {p.characteristics.filter(c => c.name === "Superficie total")
                    .length > 0 && (
                    <span>
                      <i className="icon-area"></i>
                      {
                        p.characteristics.filter(
                          c => c.name === "Superficie total"
                        )[0].value
                      }{" "}
                      mt2 tot.
                    </span>
                  )}

                  {p.characteristics.filter(c => c.name === "Superficie útil")
                    .length > 0 && (
                    <span>
                      <i className="icon-area"></i>
                      {
                        p.characteristics.filter(
                          c => c.name === "Superficie útil"
                        )[0].value
                      }{" "}
                      mt2 const.
                    </span>
                  )}

                  {p.characteristics.filter(c => c.name === "Habitaciones")
                    .length > 0 && (
                    <span>
                      <i className="icon-bed"></i>
                      {
                        p.characteristics.filter(
                          c => c.name === "Habitaciones"
                        )[0].value
                      }
                      &nbsp;Hab.
                    </span>
                  )}
                  {p.characteristics.filter(c => c.name === "Baños").length >
                    0 && (
                    <span>
                      <i className="icon-bath"></i>
                      {
                        p.characteristics.filter(c => c.name === "Baños")[0]
                          .value
                      }
                      &nbsp;Baños
                    </span>
                  )}

                  {p.characteristics.filter(c => c.name === "Estacionamientos")
                    .length > 0 && (
                    <span>
                      <i className="icon-garage"></i>
                      {
                        p.characteristics.filter(
                          c => c.name === "Estacionamientos"
                        )[0].value
                      }
                      &nbsp;Est.
                    </span>
                  )}
                </div>
              </article>
            </div>
          ))}
          {!loading && (
            <RenderPagination
              currentPage={parseInt(respPropiedades.page)}
              lastPage={parseInt(
                respPropiedades.totalRegistersQuery / respPropiedades.perPage
              ).toFixed(0)}
              previusPage={parseInt(respPropiedades.page) - 1}
              location={props.location}
            />
          )}
        </Fragment>
      )}
    </div>
  )
}

export default PropertyItem
