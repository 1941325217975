import React, { Fragment, useState } from "react"
import "./react-suggestions.css"
import comunas from "../../assets/data/comunas.json"
import AutoSuggest from "react-autosuggest"

const lowerCasedComunes = comunas.map(c => {
  return {
    id: c.id,
    nombre: c.nombre.toUpperCase(),
  }
})

String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1)
}

const FilterProperties = () => {
  const [value, setValue] = useState("")
  const [suggestions, setSuggestions] = useState([])
  const [idComuna, setIdComuna] = useState("")
  const [formCodigo, setFormCodigo] = useState(false)

  function getSuggestions(value) {
    return lowerCasedComunes.filter(c =>
      c.nombre.includes(value.trim().toUpperCase())
    )
  }

  return (
    <Fragment>
      {!formCodigo ? (
        <form
          className="advance-search-form clearfix"
          action="/propiedades"
          method="get"
        >
          <div className="option-bar">
            <label>Estado</label>
            <span className="selectwrap">
              <select
                name="status"
                id="select-status"
                className="search-select"
              >
                 <option value="" selected="selected">
                  Todas
                </option>
                <option value="VENTA">Venta</option>
                <option value="ARRIENDO">Arriendo</option>
               
              </select>
            </span>
          </div>

          <div className="option-bar">
            <label>Tipo</label>
            <span className="selectwrap">
              <select name="type" id="select-status" className="search-select">
                <option value="" selected>
                  Tipo
                </option>
                <option value="CASA">Casa</option>
                <option value="DEPARTAMENTO">Departamento</option>
                <option value="OFICINA">Oficina</option>
                <option value="ESTACIONAMIENTO">Estacionamiento</option>
                <option value="LOCAL">Local</option>
                <option value="PARCELA">Parcela</option>
                <option value="SITIO">Sitio</option>
                <option value="INDUSTRIAL">Industrial</option>
                <option value="AGRICOLA">Agricola</option>
                <option value="BODEGA">Bodega</option>
               {/*  <option value="SITIO">Terreno</option> */}
                <option value="LOTEO">Loteo</option>
                <option value="CABANA">Cabaña</option>
                <option value="HOTEL"> Hotel / Apart hotel</option>
                <option value="NEGOCIO">Negocio / derecho de llave</option>
                <option value="PANO">Paño Inmobilario</option>
              </select>
            </span>
          </div>

          <div className="option-bar">
            <label>Comuna</label>
            <span>
              <AutoSuggest
                suggestions={suggestions}
                onSuggestionsClearRequested={() => {
                  setSuggestions([])
                }}
                onSuggestionsFetchRequested={({ value }) => {
                  setValue(value)
                  setSuggestions(getSuggestions(value))
                }}
                onSuggestionSelected={(_, { suggestionValue }) => {
                  const searchValue = lowerCasedComunes.filter(c =>
                    c.nombre.includes(suggestionValue)
                  )
                  if (searchValue[0].id) {
                    setIdComuna(
                      searchValue[0].nombre
                        .toLowerCase()
                        .replace(/\w\S*/g, w =>
                          w.replace(/^\w/, c => c.toUpperCase())
                        )
                    )
                  } else {
                    setIdComuna("")
                  }
                }}
                getSuggestionValue={suggestion => suggestion.nombre}
                renderSuggestion={suggestion => (
                  <span>{suggestion.nombre}</span>
                )}
                inputProps={{
                  placeholder: "Ingrese una comuna",
                  value: value,
                  onChange: (_, { newValue, method }) => {
                    setValue(newValue)
                  },
                }}
                highlightFirstSuggestion={true}
              />
            </span>
          </div>

          <input type="hidden" name="comuna" value={idComuna} />

          {/*  <div className="option-bar">
              <label>Moneda</label>
              <span className="selectwrap">
                <select
                  name="sortBy"
                  className="search-select-currency"
                  style={{ width: "50px" }}
                >
                  <option value="clp" selected>
                    $
                  </option>
                  <option value="uf">UF</option>
                </select>
              </span>
            </div> */}

          <div className="option-bar">
            <label>Ordenar</label>
            <span className="selectwrap">
              <select name="order" className="search-select">
              <option value="" selected>
                 Seleccione
                </option>
              <option value="" selected>
                 Más nuevas 
                </option>
                <option value="asc" >
                  Menor precio 
                </option>
                <option value="desc">Mayor precio </option>
              </select>
            </span>
          </div>

         {/*  <input type="hidden" name="orderBy" value="value" /> */}
          <input
            type="submit"
            value="Buscar"
            style={{ marginLeft: "25px" }}
            className=" real-btn btn"
          />
        </form>
      ) : (
        <form
          className="advance-search-form clearfix"
          action="/propiedades"
          method="get"
        >
          <div className="option-bar">
            <label>Código Interno</label>

            <input
              type="number"
              name="code"
              className="search-input"
              style={{ height: "20px", borderRadius: "4px" }}
              title="Ingrese el código de la propiedad"
            ></input>
          </div>
          <input type="submit" value="Buscar" className="real-btn btn" />
        </form>
      )}
      <div style={{ marginBottom: "20px" }}>
        {" "}
        <input
          type="checkbox"
          onClick={() => setFormCodigo(!formCodigo)}
        />{" "}
        <span style={{ verticalAlign: "text-top" }}>
          {" "}
          Busqueda por código interno
        </span>
      </div>
    </Fragment>
  )
}

export default FilterProperties
