import React, { Fragment, useState } from "react"
import { Link } from "gatsby"
import { navigate } from "@reach/router"

const Navigate = () => {
  const navigateTo = e => {
    navigate(e.target.value)
  }
  return (
    <Fragment>
      <nav className="main-menu">
        <ul>
          <Link to="/">
            <li>
              <a href="">Home</a>
            </li>
          </Link>
          <Link to="quienes-somos">
            <li>
              <a href="">Quiénes somos</a>
            </li>
          </Link>

          <li className="menu-servicio-profesional">
            <a>Servicios</a>
            <ul className="submenu-servicio-profesional">
              <li>
                <a target="_blank" href="https://arriendoinmobiliario.cl/">
                  Arriende con nosotros, conozca nuestras propiedades
                </a>
              </li>
              <li>
                <Link to="/entreguenos-su-propiedad">
                  Venda, arriende y administre con nosotros
                </Link>
              </li>

              <li>
                <Link to="/propiedades-en-remate">
                  Propiedades en remate, vendemos su propiedad
                </Link>
              </li>
            </ul>
          </li>

          <Link to="propiedades">
            <li>
              <a href="">Propiedades</a>
            </li>
          </Link>
          {/*
          //<Link to="equipo">
          //  <li>
          //    <a href="">Ejecutivos</a>
          //  </li>
          //</Link>
          */}
          <Link to="contacto">
            <li>
              <a href="">Contáctenos</a>
            </li>
          </Link>
        </ul>

        <select class="responsive-nav" onChange={navigateTo}>
          <option value="" selected="">
            Menú
          </option>
          <option value="/"> Home</option>
          <option value="/quienes-somos"> Quiénes Somos</option>
          <option value="/entreguenos-su-propiedad">
            Venda o Arriende con nosotros
          </option>
          <option value="/propiedades">Propiedades</option>
          <option value="/equipo">Equipo</option>
          <option value="/contacto">Contáctenos</option>
        </select>
      </nav>
    </Fragment>
  )
}

export default Navigate
