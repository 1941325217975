import React, { Fragment } from "react"
import { FaInstagram } from "react-icons/fa"

import Navigate from "./navigate"
import Logo from "../images/logoblackfin.png"
import logoAIWhite from "../assets/images/partners/logoAIalowne.png"
import { Link, navigateTo } from "gatsby"

const Header = ({ siteTitle }) => {
  return (
    <Fragment>
      <div className="header-wrapper">
        <div className="container">
          <header id="header" className="clearfix">
            <div id="header-top" className="clearfix">
              <h2 id="contact-email">
                <i className="email"></i>
                <a href="mailto:propiedades@agenteinmobiliario.cl">
                  propiedades@agenteinmobiliario.cl
                </a>
              </h2>

              <ul className="social_networks clearfix">
                <li className="rss">
                  <a
                    target="blank"
                    href="https://webmail.agenteinmobiliario.cl/"
                  >
                    <i className="icon-signin"></i>
                  </a>
                </li>
                <li className="facebook">
                  <a
                    target="blank"
                    href="https://web.facebook.com/www.agenteinmobiliario.cl"
                  >
                    <i className="icon-facebook"></i>
                  </a>
                </li>

                <li className="linkedin">
                  <a
                    target="blank"
                    href="https://www.linkedin.com/company/agenteinmobiliario/"
                  >
                    <i className="icon-linkedin"></i>
                  </a>
                </li>

                <li className="instagram">
                  <a
                    target="blank"
                    href="https://www.instagram.com/agenteinmobiliario_cl/"
                  >
                    <FaInstagram style={{ margin: "-3px" }} />
                  </a>
                </li>

                {/*  <li className="arriendoinmobiliario">
                  {"  "}
                  <a href="https://arriendoinmobiliario.cl/" target="_blank">
                    <img src={logoAIWhite} style={{ width: "20px" }} />
                  </a>
                </li> */}
                <li>
                  <button
                    className="btn-grey btn-mini btn-trabaja-con-nosotros"
                    onClick={() => navigateTo("trabaje-con-nosotros")}
                  >
                    Trabaja con nosotros!
                  </button>
                </li>
              </ul>
            </div>

            <div id="logo">
              <Link to="/">
                <img src={Logo} />
              </Link>

              <div className="tag-line">
                <span style={{ backgroundColor: "#e67631", color: "#fff" }}>
                  Portal Venta y Arriendo
                </span>
              </div>
            </div>

            <div className="menu-and-contact-wrap">
              <h2 className="contact-number">
                <a href="tel:56 9 3380 7662" style={{ color: "#fff" }}>
                  <i className="icon-phone"></i>9 3380 7662
                </a>

                <span className="outer-strip"></span>
              </h2>
              <Navigate />
            </div>
          </header>
        </div>
      </div>
    </Fragment>
  )
}

export default Header
