import React, { useState } from "react"
import { Helmet } from "react-helmet"
import Slider from "react-slick"
import {
  RiBuildingLine,
  RiBookmark3Line,
  RiHomeSmileLine,
  RiBarChart2Line,
  RiUserStarLine,
  RiExchangeDollarLine,
} from "react-icons/ri"

import Layout from "../components/layout"
import imagen1 from "../assets/images/temp-images/Casas.jpeg"
import imagen2 from "../assets/images/temp-images/Departamentos.jpeg"
import imagen3 from "../assets/images/temp-images/Parcelas.jpeg"
import imagen4 from "../assets/images/temp-images/Proyectos inmobiliarios.jpeg"
import imagen5 from "../assets/images/temp-images/Oficinas.jpg"
import imagen6 from "../assets/images/temp-images/Arriendos.jpeg"
import imagen7 from "../assets/images/temp-images/pano.jpg"
import Patner1 from "../assets/images/partners/logotrustrent.png"
import Patner2 from "../assets/images/partners/logoAI.png"
import Patner3 from "../assets/images/partners/circulo-inversionista-2-1.jpg"
import Patner4 from "../assets/images/partners/garantia.png"
import Patner5 from "../assets/images/partners/garanchil.png"

import Logo from "../assets/images/logo2.png"

import SEO from "../components/seo"
import { Banner1 } from "../components/imagesSystem"
import PropertyItem from "../components/properties/PropertyItem"
import FilterProperties from "../components/properties/FilterProperties"
import CountUp from "react-countup"

const defaultFormContact = {
  nombre: "",
  correo: "",
  celular: "",
  asunto: "Te contactaron de agenteinmobiliario.com",
  mensaje: "",
  loading: false,
  request: false,
  requestMessage: "",
}

const IndexPage = props => {
  const settingsSlick = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
  }

  const [form, setForm] = useState(defaultFormContact)
  const onChangeInput = e => {
    setForm({ ...form, [e.target.name]: e.target.value })
  }
  const requestForm = async () => {
    setForm({ ...form, loading: true })
    var formdata = new FormData()
    formdata.append("name", form.nombre)
    formdata.append("email", form.correo)
    formdata.append("mobile", form.celular)
    formdata.append("subject", form.asunto)
    formdata.append("message", form.mensaje)

    setForm({ loading: true })
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    }

    fetch(
      "https://www.agenteinmobiliario.cl/mailApi/sendmail.php",
      requestOptions
    )
      .then(response => response.text())
      .then(result => {
        handleFormReset()
        setForm({
          ...defaultFormContact,
          loading: false,
          requestMessage: <p style={{ color: "#000" }}>Mensaje enviado!</p>,
        })
      })
      .catch(error =>
        setForm({
          ...form,
          loading: false,
          requestMessage: (
            <p style={{ color: "#000" }}>Error intenta nuevamente!</p>
          ),
        })
      )
  }
  const onSubmit = e => {
    e.preventDefault()
    requestForm()
  }

  const handleFormReset = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      input => (input.value = "")
    )

    Array.from(document.querySelectorAll("textarea")).forEach(
      textarea => (textarea.value = "")
    )
  }

  const myExtScript = require("../components/cliengo")
  return (
    <Layout>
      <Helmet>
        <link
          rel="stylesheet"
          type="text/css"
          charset="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
      </Helmet>
      <SEO title="Home" />
      <div
        id="home-flexslider"
        className="clearfix"
        style={{ position: "relative", height: "500px" }}
      >
        <div className="flexslider">
          <Banner1 />
          <div className="desc-wrap">
            <div className="slide-description">
              <span class="phone_icon">
                <i class="icon-phone"></i>
              </span>
              <h3>
                <a href="#">¡Permítanos llamarlo! </a>
                <span style={{ float: "right", paddingRight: "50px" }}>
                  <img src={Logo} />
                </span>
              </h3>

              <p>Para ayudarlo a elegir su propiedad</p>
              <form
                class="contact-form-landing"
                onSubmit={event => onSubmit(event)}
              >
                <div class="row-fluid">
                  <div class="span6">
                    <input
                      type="text"
                      name="nombre"
                      onChange={event => onChangeInput(event)}
                      placeholder="Su nombre"
                      required
                    />
                  </div>
                  <div class="span6">
                    <input
                      type="number"
                      name="celular"
                      onChange={event => onChangeInput(event)}
                      placeholder="Su teléfono"
                      required
                    />
                  </div>
                </div>
                <div class="row-fluid">
                  <div class="span12">
                    <input
                      type="email"
                      name="correo"
                      onChange={event => onChangeInput(event)}
                      style={{ width: "98%" }}
                      placeholder="Su correo electrónico"
                      required
                    />
                  </div>
                </div>
                <div class="row-fluid">
                  <div class="span12">
                    <textarea
                      placeholder="Ingrese su mensaje"
                      rows="12"
                      name="mensaje"
                      onChange={event => onChangeInput(event)}
                      style={{ width: "98%", height: "50px" }}
                    ></textarea>
                  </div>
                </div>
                <div class="row-fluid" style={{ display: "flex" }}>
                  <input
                    type="checkbox"
                    title="* Porfavor, acepte las condiciones"
                    required
                  />

                  <label style={{ padding: "0px 0px 20px 10px" }}>
                    Doy mi consentimiento para que este sitio web almacene la
                    información enviada para que puedan responder a mi consulta.
                  </label>
                </div>
                <div class="row-fluid" style={{ marginBottom: "-15px" }}>
                  <div style={{ display: "flex" }}>
                    {" "}
                    <input
                      disabled={form.loading}
                      type="submit"
                      value={form.loading ? "Enviando" : "Enviar mensaje"}
                      id="submit"
                      className="real-btn"
                      name="submit"
                    />
                    <div
                      style={{
                        padding: "5px 20px",
                        fontSize: "15px",
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      {form.requestMessage !== "" &&
                        !form.loading &&
                        form.requestMessage}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="container contents single">
        <div class="row">
          <div class="span12 main-wrap">
            <div class="main">
              <div class="inner-wrapper">
                <section className="advance-search">
                  <h3 className="search-heading">
                    <i className="icon-search"></i>Encuentre su nueva Propiedad
                    Aquí
                  </h3>
                  <div className="as-form-wrap">
                    <FilterProperties />
                  </div>
                </section>

                <section className="property-items">
                  <div className="narrative">
                    <h2>
                      ¡Encuentre con nosotros la mejor oferta inmobiliaria!
                    </h2>
                    <p>
                      En estos 17 años hemos vendido más de 2.700 inmuebles,
                      agradecemos la confianza depositada por nuestros clientes.
                      Revise nuestras ofertas de propiedades publicadas,
                      nuestros agentes inmobiliarios están esperando su consulta
                      y orden de visita. Busque su nueva casa, departamento,
                      parcela o arriendo con nosotros, estaremos encantados de
                      atenderlo. Lo asesoramos y gestionamos su crédito para que
                      tome la mejor decisión de compra.
                    </p>
                  </div>

                  <div className="mention">
                    “17 años de experiencia avalan nuestros resultados”
                  </div>
                  <div className="property-items-container clearfix">
                    <PropertyItem {...props} />
                  </div>
                </section>
                <section>
                  <div
                    id="gallery-container"
                    style={{ backgroundColor: "#f5f5f5" }}
                  >
                    <h2
                      style={{
                        margin: "20px 0px 40px",
                        color: "#757171",
                        textAlign: "center",
                      }}
                    >
                      Categorías Destacadas
                    </h2>
                    <div class="row-fluid">
                      <div className="span6">
                        {" "}
                        <a
                          href={`/propiedades/?status=&type=CASA&comuna=&currency=CLP&order=asc&orderBy=value`}
                        >
                          <div className=" ">
                            <figure className="div-imagen">
                              <div className="item-title">
                                <h2>Casas</h2>
                              </div>
                              <img
                                className="img-border"
                                src={imagen1}
                                alt="15421 Southwest 39th Terrace"
                              />
                            </figure>
                          </div>
                        </a>
                      </div>
                      <div className="span6">
                        {" "}
                        <a
                          href={`/propiedades/?status=&type=DEPARTAMENTO&comuna=&currency=CLP&order=asc&orderBy=value`}
                        >
                          <div className=" ">
                            <figure className="div-imagen">
                              <div className="item-title">
                                <h2>Departamentos</h2>
                              </div>
                              <img
                                className="img-border desvanecer"
                                src={imagen2}
                                alt="700 Front Street, Key West, FL"
                              />
                            </figure>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div class="row-fluid">
                      <div className="span6">
                        {" "}
                        <a
                          href={`/propiedades/?status=&type=PARCELA&comuna=&currency=CLP&order=asc&orderBy=value`}
                        >
                          <div className="gallery-item isotope-item on-rent ">
                            <figure className="div-imagen">
                              <div className="item-title">
                                <h2>Parcelas</h2>
                              </div>
                              <img
                                className="img-border"
                                src={imagen3}
                                alt="15421 Southwest 39th Terrace"
                              />
                            </figure>
                          </div>
                        </a>
                      </div>
                      <div className="span6">
                        {" "}
                        <a
                          href={`/propiedades/?status=todas&type=todas&comuna=todas&orderby=null&order=ASC&project=true`}
                        >
                          <div className=" gallery-item isotope-item on-sale ">
                            <figure className="div-imagen">
                              <div className="item-title">
                                <h2>Proyectos Inmobiliarios</h2>
                              </div>
                              <img
                                className="img-border desvanecer"
                                src={imagen4}
                                alt="700 Front Street, Key West, FL"
                              />
                            </figure>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div class="row-fluid">
                      <div className="span6">
                        {" "}
                        <a
                          href={`/propiedades/?status=&type=OFICINA&comuna=&currency=CLP&order=asc&orderBy=value`}
                        >
                          <div className="gallery-item isotope-item on-rent ">
                            <figure className="div-imagen">
                              <div className="item-title">
                                <h2>Oficinas</h2>
                              </div>
                              <img
                                className="img-border"
                                src={imagen5}
                                alt="15421 Southwest 39th Terrace"
                              />
                            </figure>
                          </div>
                        </a>
                      </div>
                      <div className="span6">
                        {" "}
                        <a
                          href={`/propiedades/?status=ARRIENDO&type=&comuna=&currency=CLP&order=asc&orderBy=value`}
                        >
                          <div className=" gallery-item isotope-item on-sale ">
                            <figure className="div-imagen">
                              <div className="item-title">
                                <h2>Arriendos</h2>
                              </div>
                              <img
                                className="img-border desvanecer"
                                src={imagen6}
                                alt="700 Front Street, Key West, FL"
                              />
                            </figure>
                          </div>
                        </a>
                      </div>
                    </div>
                    {/*
                    <div class="row-fluid">
                      <div className="span6">
                        {" "}
                        <a
                          href={`/propiedades/?status=&type=PANO&comuna=&currency=CLP&order=asc&orderBy=value`}
                        >
                          <div className="gallery-item isotope-item on-rent ">
                            <figure className="div-imagen">
                              <div className="item-title">
                                <h2>Paños Inmobiliarios</h2>
                              </div>
                              <img
                                className="img-border"
                                src={imagen7}
                                alt="15421 Southwest 39th Terrace"
                              />
                            </figure>
                          </div>
                        </a>
                      </div>
                  
                    </div>
                    */}
                  </div>
                </section>
                <section class="brands-carousel clearfix">
                  <div id="like_button_container"></div>
                  <h3 style={{ marginTop: "-20px" }}>
                    <span>Nuestros Partners</span>
                  </h3>
                  <div class="row-fluid">
                    <Slider {...settingsSlick}>
                      <div>
                        <a
                          target="_blank"
                          href="https://www.trustrent.cl/#!/trustrent"
                          title="trustrent.cl"
                        >
                          <img
                            src={Patner1}
                            alt="trustrent.cl"
                            title="trustrent.cl"
                            style={{ width: "80%", paddingTop: "40px" }}
                          />
                        </a>
                      </div>
                      <div
                        class="span3"
                        style={{ padding: "50px 0px", marginTop: "20px" }}
                      >
                        <p></p>

                        <a
                          target="_blank"
                          href="https://www.unnio.cl/"
                          title="www.unnio.cl"
                        >
                          <img
                            style={{ width: "90%", paddingTop: "20px" }}
                            src="https://www.unnio.cl/wp-content/themes/unnio/img/logo/logo-color.svg"
                            alt="www.unnio.cl"
                            title="www.unnio.cl"
                          />
                        </a>
                      </div>
                      <div>
                        <p></p>
                        <a
                          target="_blank"
                          href="https://arriendoinmobiliario.cl/"
                          title="Arriendo Inmobiliario"
                        >
                          <img
                            src={Patner2}
                            alt="Arriendo Inmobiliario"
                            title="Arriendo Inmobiliario"
                            style={{ width: "170px", margin: "0 auto" }}
                          />
                        </a>{" "}
                      </div>
                      <div>
                        <p></p>
                        <a
                          target="_blank"
                          href="https://www.circuloinversionista.cl/"
                          title="Círculo Inversionista"
                        >
                          <img
                            src="https://static.wixstatic.com/media/aaafed_ba7b9027271d4f1c96548aa795673f3c~mv2.png/v1/fill/w_263,h_106,al_c,lg_1,q_85/circulo-inversionista-.webp"
                            alt="Círculo Inversionista"
                            title="Círculo Inversionista"
                          />
                        </a>
                      </div>
                      <div>
                        <p></p>
                        <a
                          target="_blank"
                          href="https://arriendoconpolizadegarantia.cl/"
                          title="Garantía de Arriendo"
                        >
                          <img
                            src={Patner4}
                            style={{ width: "90%", paddingTop: "10px" }}
                            alt="Garantía de Arriendo"
                            title="Garantía de Arriendo"
                          />
                        </a>
                      </div>
                      <div>
                        <p></p>
                        <a target="_blank" title="Garanchil">
                          <img
                            src={Patner5}
                            style={{ width: "90%", paddingTop: "10px" }}
                            alt="Garanchil"
                            title="Garanchil"
                          />
                        </a>
                      </div>
                    </Slider>
                  </div>
                </section>

                <section
                  class=" counterup"
                  style={{
                    backgroundColor: "#f5f5f5",
                    border: "none",
                    textAlign: "center",
                  }}
                >
                  <div class="container">
                    <div
                      class="row-fluid"
                      style={{ margin: "0 auto", maxWidth: "1100px" }}
                    >
                      <div class="span2">
                        <div className="card" style={{ width: "18rem" }}>
                          <RiBuildingLine className="icon-counter" />
                          <div className="card-body">
                            <h5 className="card-title"> Propiedades</h5>
                            <h4 className="card-text">
                              +{" "}
                              <CountUp
                                delay={5}
                                start={0}
                                end={1250}
                                duration={100.55}
                              />
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div class="span2">
                        <div className="card" style={{ width: "18rem" }}>
                          <RiUserStarLine className="icon-counter" />
                          <div className="card-body">
                            <h5 className="card-title"> Años de Experiencia</h5>
                            <h4 className="card-text">
                              +{" "}
                              <CountUp
                                delay={5}
                                start={0}
                                end={17}
                                duration={100.55}
                              />
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div class="span2">
                        <div className="card" style={{ width: "18rem" }}>
                          <RiExchangeDollarLine className="icon-counter" />
                          <div className="card-body">
                            <h5 className="card-title"> Morosidad</h5>
                            <h4 className="card-text">0,5 %</h4>
                          </div>
                        </div>
                      </div>
                      <div class="span2">
                        <div className="card" style={{ width: "18rem" }}>
                          <RiBarChart2Line className="icon-counter" />
                          <div className="card-body">
                            <h5 className="card-title">
                              {" "}
                              Valoración de cartera
                            </h5>
                            <h4 className="card-text">
                              {" "}
                              UF
                              <CountUp
                                start={0}
                                end={4172727}
                                duration={102.55}
                                delay={5}
                              />
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div class="span2">
                        <div className="card" style={{ width: "18rem" }}>
                          <RiHomeSmileLine className="icon-counter" />
                          <div className="card-body">
                            <h5 className="card-title"> Vacancia</h5>
                            <h4 className="card-text">0,5 %</h4>
                          </div>
                        </div>
                      </div>
                      {/*      

              

                     
                      <div class="span2">
                        <div class="countr">
                          <div className="container-icon-couter">
                            <RiHomeSmileLine className="icon-counter" />
                          </div>
                          <div class="count-me">
                            <h3 class="counter text-left">
                              <CountUp
                                start={0}
                                end={-1}
                                duration={50.55}
                                delay={5}
                              />{" "}
                              %
                            </h3>
                            <h5>Vacancia</h5>
                          </div>
                        </div>
                      </div>
                 */}{" "}
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
